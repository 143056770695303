import { useState, useEffect, useCallback } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useObserver, useLocalStore } from "mobx-react"

import { socket, twitches } from "../../store"
import { Adder, Field, Sortable, ConfirmButton } from "./form"

const Twitch = ({ twitch, onDelete = () => {} }) => {
  const [isEdit, setIsEdit] = useState(false)
  const myTwitch = useLocalStore(() => twitch)
  useEffect(() => {
    Object.assign(myTwitch, twitch)
  }, [twitch, myTwitch])

  const updateTwitchInfo = useCallback(twitch => {
    socket.sendObj({
      twitchInfo: [twitch],
      admin: true,
    })
  }, [])

  return useObserver(() => (
    <article
      css={css`
        background-color: black;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        padding: 1em;
      `}
    >
      <div>
        <img
          src={myTwitch.icon}
          alt=""
          css={css`
            width: 4em;
            height: auto;
          `}
        />
        {myTwitch.label}
      </div>
      <div>
        {!!isEdit && (
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
            `}
          >
            <Field label="key" value={myTwitch.key || ""} disabled />
            <Field
              label="label"
              value={myTwitch.label || ""}
              setValue={value => {
                myTwitch.label = value
                updateTwitchInfo(myTwitch)
              }}
            />
            <Field
              label="isDj"
              value={myTwitch.isDj}
              type="checkbox"
              setValue={value => {
                myTwitch.isDj = value
                updateTwitchInfo(myTwitch)
              }}
            />
            <Field
              label="icon"
              value={myTwitch.icon || ""}
              setValue={value => {
                myTwitch.icon = value
                updateTwitchInfo(myTwitch)
              }}
            />
            <Field
              label="has chat"
              type="checkbox"
              value={!!myTwitch.hasChat}
              setValue={value => {
                myTwitch.hasChat = value
                updateTwitchInfo(myTwitch)
              }}
            />
            <Field
              label="channel"
              value={myTwitch.channel || ""}
              setValue={value => {
                myTwitch.channel = value
                updateTwitchInfo(myTwitch)
              }}
            />
          </div>
        )}
      </div>
      <div
        css={css`
          min-height: 2em;
          display: flex;
          justify-content: flex-end;
        `}
      >
        <ConfirmButton
          onClick={onDelete}
          css={css`
            margin-right: auto;
          `}
        >
          Delete
        </ConfirmButton>
        <button onClick={() => setIsEdit(!isEdit)}>Edit</button>
        <label
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          Active:{" "}
          <input
            type="checkbox"
            checked={myTwitch.enabled}
            onChange={evt => {
              myTwitch.enabled = evt.target.checked
              updateTwitchInfo(myTwitch)
            }}
          />
        </label>
      </div>
    </article>
  ))
}

const Edit = () => {
  const myTwitches = useLocalStore(() => twitches)

  useEffect(() => {
    if (!twitches) return
    myTwitches.replace(
      twitches.sort((a, b) => {
        return parseInt(a.sort) > parseInt(b.sort) ? 1 : -1
      })
    )
  }, [myTwitches])

  if (!myTwitches) return null
  return (
    <section>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <h1>TWITCHES</h1>
        <Adder
          onAdd={key => {
            socket.sendObj({
              twitchInfo: [{ key: key, sort: myTwitches.length + 1 }],
              admin: true,
            })
          }}
        />
      </div>
      <Sortable
        onSortEnd={({ oldIndex, newIndex }) => {
          const oldSort = myTwitches[oldIndex].sort
          const newSort = myTwitches[newIndex].sort
          const newTwitches = myTwitches
            .map((twitch, i) => {
              return {
                ...twitch,
                sort:
                  i === oldIndex
                    ? newSort
                    : i === newIndex
                    ? oldSort
                    : twitch.sort,
              }
            })
            .sort((a, b) => {
              return parseInt(a.sort) > parseInt(b.sort) ? 1 : -1
            })
          myTwitches.replace(newTwitches)
          socket.sendObj({
            twitchInfo: newTwitches,
            admin: true,
          })
        }}
      >
        {twitches.map(twitch => (
          <Twitch
            key={twitch.key}
            twitch={twitch}
            onDelete={() => {
              socket.sendObj({
                deleteTwitch: twitch.key,
                admin: true,
              })
            }}
          />
        ))}
      </Sortable>
    </section>
  )
}

const Twitches = () => {
  return useObserver(() => <Edit twitches={twitches.map(t => t)} />)
}

export default Twitches
