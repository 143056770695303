/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useState, useEffect } from "react"
import { observer } from "mobx-react"

import { socket, users, user } from "../store"
import SEO from "../components/seo"
import Users from "../components/admin/users"
import Rooms from "../components/admin/room"
import Twitches from "../components/admin/twitch"
import Wuff from "../components/admin/wuff"
import LabelValue from "../components/labelValue"
import ChatOutput from "../components/chat/chatOutput"
import ChatInput from "../components/chat/ChatInput"

const ServerInfo = observer(() => {
  return (
    <LabelValue
      textAlign="right"
      label="users"
      value={users.info && users.info.length}
    />
  )
})

const UserInfo = observer(props => {
  return (
    <LabelValue
      textAlign="right"
      label={`${
        socket.isOpen
          ? "connected"
          : socket.isConnecting
          ? "connecting"
          : "diconnected"
      }`}
      value={user.nickName}
      {...props}
    />
  )
})

const Chat = observer(props => {
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <ChatOutput chatId="admin" />
      <ChatInput chatId="admin" />
    </div>
  )
})

const Admin = () => {
  const [view, setView] = useState("jitsi")
  useEffect(() => {
    user.viewMode = "admin"
    socket.sendObj({
      userInfo: [user],
    })
  }, [])
  return (
    <div
      css={css`
        pointer-events: all;
      `}
    >
      <SEO title="Admin Area" />
      <div
        css={css`
          position: sticky;
          top: 0;
          background: black;
          padding: 1em;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            flex-wrap: wrap;
          `}
        >
          <h1>
            WOFF
            <br />
            ADMIN
          </h1>
          <Wuff
            css={css`
              flex: 1 1 auto;
            `}
          />
        </div>

        <div
          css={css`
            display: flex;
            align-items: center;
            flex-wrap: wrap;
          `}
        >
          <button onClick={() => setView("jitsi")} disabled={view === "jitsi"}>
            JITSI
          </button>
          <button
            onClick={() => setView("twitch")}
            disabled={view === "twitch"}
          >
            TWITCH
          </button>
          <button onClick={() => setView("users")} disabled={view === "users"}>
            USERS
          </button>
          <UserInfo
            css={css`
              margin-left: auto;
              margin-right: 0.4em;
            `}
          />
          <ServerInfo />
        </div>
      </div>
      <div
        css={css`
          padding: 1em;
        `}
      >
        {view === "jitsi" && <Rooms />}
        {view === "twitch" && <Twitches />}
        {view === "users" && <Users />}
      </div>
      <div
        css={css`
          position: sticky;
          bottom: 0;
          background: black;
          padding: 1em;
        `}
      >
        <Chat />
      </div>
    </div>
  )
}

export default Admin
