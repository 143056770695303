import { useState, useEffect } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { observer } from "mobx-react"

import { wuffwoff, socket } from "../../store"
import { Field } from "./form"

const Wuff = ({ ...props }) => {
  const [value, setValue] = useState("")

  useEffect(() => {
    setValue(wuffwoff.text)
  }, [wuffwoff.text])

  return (
    <div
      css={css`
        flex: 1 1 auto;
        display: flex;
        align-items: center;
      `}
    >
      <Field value={value} setValue={setValue} {...props} />
      <button
        disabled={wuffwoff.text === value}
        onClick={() => {
          socket.sendObj({
            wuffwoff: value,
          })
        }}
      >
        Ok
      </button>
    </div>
  )
}

export default observer(Wuff)
