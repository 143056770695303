/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useObserver } from "mobx-react"

import { users } from "../../store"

const Item = ({ children }) => (
  <div
    css={css`
      flex: 0 0 20%;
    `}
  >
    <div
      css={css`
        padding: 1em;
      `}
    >
      {children}
    </div>
  </div>
)
const User = ({ user }) => {
  return useObserver(() => (
    <article
      css={css`
        background-color: black;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      `}
    >
      {["id", "nickName", "viewMode", "jitsiRoom", "twitchChannel"].map(k => (
        <Item key={k}>{user[k] || "-"}</Item>
      ))}
    </article>
  ))
}

const Users = () => {
  return useObserver(() => (
    <section>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <h1>USERS</h1>
      </div>
      <div>
        {users.info.map(user => (
          <User key={user.id} user={user} />
        ))}
      </div>
    </section>
  ))
}

export default Users
