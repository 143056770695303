import { useState, useEffect, useCallback } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { useObserver, useLocalStore } from "mobx-react"

import { socket, rooms } from "../../store"
import { Adder, Field, Sortable, ConfirmButton } from "./form"

const Room = ({ room, onDelete = () => {} }) => {
  const [isEdit, setIsEdit] = useState(false)
  const myRoom = useLocalStore(() => room)
  useEffect(() => {
    Object.assign(myRoom, room)
  }, [room, myRoom])

  const updateRoomInfo = useCallback(room => {
    socket.sendObj({
      roomInfo: [room],
      admin: true,
    })
  }, [])
  const maxUsers = room.maxUsers ? room.maxUsers : false
  return useObserver(() => (
    <article
      css={css`
        background-color: black;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        padding: 1em;
      `}
    >
      <div>
        {myRoom.name}
        <div>
          {!!myRoom.icon && (
            <img
              src={myRoom.icon}
              alt=""
              css={css`
                width: 4em;
                height: auto;
              `}
            />
          )}
          {!!myRoom.decoration && (
            <img
              src={myRoom.decoration}
              alt=""
              css={css`
                width: 4em;
                height: auto;
              `}
            />
          )}
        </div>
        {isEdit && (
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
            `}
          >
            <Field
              label="label"
              value={myRoom.label}
              setValue={value => {
                myRoom.label = value
                updateRoomInfo(myRoom)
              }}
            />
            <Field
              label="icon"
              value={myRoom.icon}
              setValue={value => {
                myRoom.icon = value
                updateRoomInfo(myRoom)
              }}
            />
            <Field
              label="decoration"
              value={myRoom.decoration}
              setValue={value => {
                myRoom.decoration = value
                updateRoomInfo(myRoom)
              }}
            />
            <Field
              label="meetMeLabel"
              value={myRoom.meetMeLabel}
              setValue={value => {
                myRoom.meetMeLabel = value
                updateRoomInfo(myRoom)
              }}
            />
            <Field
              label="muteAudio"
              value={myRoom.muteAudio}
              type="checkbox"
              setValue={value => {
                myRoom.muteAudio = value
                updateRoomInfo(myRoom)
              }}
            />
            <Field
              label="DJ Volume"
              value={myRoom.djVolume ? myRoom.djVolume : 0.0}
              type="number"
              inputProps={{ min: 0, max: 1, step: 0.05 }}
              setValue={value => {
                myRoom.djVolume = value
                updateRoomInfo(myRoom)
              }}
            />
            <Field
              label="maximal users"
              value={maxUsers}
              type="number"
              setValue={value => {
                myRoom.maxUsers = value
                updateRoomInfo(myRoom)
              }}
            />
          </div>
        )}
      </div>
      <div
        css={css`
          min-height: 2em;
          display: flex;
          justify-content: flex-end;
        `}
      >
        <ConfirmButton
          onClick={onDelete}
          css={css`
            margin-right: auto;
          `}
        >
          Delete
        </ConfirmButton>
        <button onClick={() => setIsEdit(!isEdit)}>Edit</button>
        <label
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          Active:{" "}
          <input
            type="checkbox"
            checked={myRoom.enabled}
            onChange={evt => {
              myRoom.enabled = evt.target.checked
              updateRoomInfo(myRoom)
            }}
          />
        </label>
      </div>
    </article>
  ))
}

const EditRooms = () => {
  const myRooms = useLocalStore(() => rooms)

  useEffect(() => {
    if (!rooms) return
    myRooms.replace(
      rooms.slice().sort((a, b) => {
        return parseInt(a.sort) > parseInt(b.sort) ? 1 : -1
      })
    )
  }, [myRooms])

  if (!myRooms) return null
  return (
    <section>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <h1>JITSI</h1>
        <Adder
          onAdd={name => {
            socket.sendObj({
              roomInfo: [{ name: name, sort: myRooms.length + 1 }],
              admin: true,
            })
          }}
        />
      </div>
      <Sortable
        onSortEnd={({ oldIndex, newIndex }) => {
          const oldSort = myRooms[oldIndex].sort
          const newSort = myRooms[newIndex].sort
          const newRooms = myRooms
            .map((room, i) => {
              return {
                ...room,
                sort:
                  i === oldIndex
                    ? newSort
                    : i === newIndex
                    ? oldSort
                    : room.sort,
              }
            })
            .sort((a, b) => {
              return parseInt(a.sort) > parseInt(b.sort) ? 1 : -1
            })
          myRooms.replace(newRooms)
          socket.sendObj({
            roomInfo: newRooms,
            admin: true,
          })
        }}
      >
        {myRooms.map(room => (
          <Room
            key={room.name}
            room={room}
            onDelete={() => {
              socket.sendObj({
                deleteRoom: room.name,
                admin: true,
              })
            }}
          />
        ))}
      </Sortable>
    </section>
  )
}

const Rooms = () => {
  return useObserver(() => <EditRooms rooms={rooms.map(r => ({ ...r }))} />)
}

export default Rooms
