import { memo, Children, useEffect, useRef, useState } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { sortableContainer, sortableElement } from "react-sortable-hoc"

export const ConfirmButton = ({ children, onClick, ...props }) => {
  const [active, setActive] = useState(false)

  return (
    <button
      onClick={() => (active ? onClick() : setActive(true))}
      onBlur={() => {
        if (active) setActive(false)
      }}
      css={
        active &&
        css`
          background: ${active ? "red" : "initial"};
        `
      }
      {...props}
    >
      {active ? "Sure???" : children}
    </button>
  )
}
export const Adder = ({ onAdd }) => {
  const [name, setName] = useState("")
  return (
    <div>
      <input
        type="text"
        value={name}
        onChange={evt => setName(evt.target.value)}
      />
      <button
        disabled={!name}
        onClick={() => {
          onAdd(name)
          setName("")
        }}
      >
        +
      </button>
    </div>
  )
}

const FieldRow = ({ label, children, ...props }) => {
  return (
    <label
      css={css`
        flex: 1 1 20em;
        display: flex;
        cursor: inherit;
      `}
      {...props}
    >
      {!!label && (
        <div
          css={css`
            flex: 0 0 6em;
            font-size: 80%;
            margin: 0.4em;
          `}
        >
          {label}
        </div>
      )}
      <div
        css={css`
          flex: 1 1 auto;
          margin: 0.4em;
        `}
      >
        {children}
      </div>
    </label>
  )
}
export const Field = ({
  label,
  value,
  inputProps,
  type = "text",
  setValue = () => {},
  disabled,
  ...props
}) => {
  const [val, setVal] = useState(value)
  const timeout = useRef()

  useEffect(() => {
    timeout.current = setTimeout(() => {
      setValue(val)
    }, 200)
    return () => clearTimeout(timeout.current)
  }, [val, setValue])

  useEffect(() => {
    setVal(value)
  }, [value, setVal])

  return (
    <FieldRow label={label} {...props}>
      <input
        type={type}
        disabled={disabled}
        value={val}
        onChange={evt =>
          type === "checkbox" ? setVal(!value) : setVal(evt.target.value)
        }
        css={css`
          box-sizing: border-box;
          padding: 0;
          width: 100%;
        `}
        checked={type === "checkbox" ? value : null}
        {...inputProps}
      />
    </FieldRow>
  )
}

const SortableItem = sortableElement(({ child }) => (
  <div
    css={css`
      cursor: move;
    `}
  >
    {child}
  </div>
))
const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>
})

export const Sortable = memo(({ onSortEnd = () => {}, children, ...props }) => {
  return (
    <SortableContainer onSortEnd={onSortEnd} {...props}>
      {Children.map(children, (child, i) => (
        <SortableItem key={child.key} child={child} index={i} />
      ))}
    </SortableContainer>
  )
})
